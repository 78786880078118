<template>
  <div class="Pools">
    <Explainer
      m="THE GATEWAY FOR DAO OWNED STAKING DERIVATIVES"
      title="Staking Universe"
      p="Geode Finance is building a trustless Staking Ecosystem for any Proof of Stake chain. "
      :back="false"
    />
    <transition name="fade" mode="out-in">
      <div class="PoolInfos" v-if="planetIds">
        <PoolInfo v-for="p in planetIds" :planet="p" :key="p.name" />
      </div>
      <div class="PoolInfos" v-else>
        <PoolInfo v-for="p in pools" :planet="p" :key="p.name" />
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PoolInfo from "./PoolInfo";
import Explainer from "@/components/utils/Explainer";
export default {
  components: { PoolInfo, Explainer },
  computed: {
    ...mapGetters({ planetIds: "planetIds" }),
  },
  data() {
    return {
      pools: [
        {
          name: "------",
          apr: 0,
          id: 0,
        },
      ],
    };
  },
};
</script>

<style scoped>
.Pools {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100vw;
  max-width: 1800px;
  width: 95%;
}

.PoolInfos {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  /* padding: 50px 20px 0 10vw; */
}
</style>