var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"PoolInfo"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"img-wrap"},[_c('ImageVue',{attrs:{"src":`logos/${_vm.planet.name}.svg`}})],1),_c('div',{staticClass:"pool-name"},[_vm._v(_vm._s(_vm.planet.name))]),_c('div',{staticClass:"pool-id"},[_vm._v(" id: "+_vm._s(_vm.planet.id ? _vm.planet.id.length > 5 ? _vm.planet.id.substring(0, 5) : 0 : 0)+"... ")]),_c('div',{staticClass:"pool-apr"},[_vm._v(" APR: "),_c('div',{staticClass:"light-green"},[_vm._v(" "+_vm._s(Number.parseFloat(_vm.planet.APR).toFixed(2))+" % ")])]),_c('div',{staticClass:"pool-buttons"},[_c('Button',{staticClass:"pool-button",attrs:{"icon":"logos/Avax.png","text":'Stake',"color":'354B3E',"fill":true,"whiteText":true},nativeOn:{"click":function($event){return _vm.$router.push({
            name: 'Portal',
            params: { id: _vm.planet.id },
          })}}}),_c('Button',{staticClass:"pool-button",attrs:{"icon":"logos/geode.png","text":'DWP',"color":'7755dd',"fill":true,"whiteText":true},nativeOn:{"click":function($event){return _vm.$router.push({
            name: 'DWP',
            params: { id: _vm.planet.id },
          })}}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }