<template>
  <div class="PoolInfo">
    <div class="content">
      <div class="img-wrap">
        <ImageVue :src="`logos/${planet.name}.svg`" />
      </div>
      <div class="pool-name">{{ planet.name }}</div>
      <div class="pool-id">
        id:
        {{
          planet.id
            ? planet.id.length > 5
              ? planet.id.substring(0, 5)
              : 0
            : 0
        }}...
      </div>
      <div class="pool-apr">
        APR:
        <div class="light-green">
          {{ Number.parseFloat(planet.APR).toFixed(2) }} %
        </div>
      </div>
      <div class="pool-buttons">
        <Button
          icon="logos/Avax.png"
          class="pool-button"
          :text="'Stake'"
          @click.native="
            $router.push({
              name: 'Portal',
              params: { id: planet.id },
            })
          "
          :color="'354B3E'"
          :fill="true"
          :whiteText="true"
        />
        <Button
          icon="logos/geode.png"
          class="pool-button"
          :text="'DWP'"
          @click.native="
            $router.push({
              name: 'DWP',
              params: { id: planet.id },
            })
          "
          :color="'7755dd'"
          :fill="true"
          :whiteText="true"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/utils/ButtonVue";
import ImageVue from "@/components/utils/ImageVue";
export default {
  components: { ImageVue, Button },
  props: ["planet"],
  data() {
    return {
      planetData: {
        APR: 0,
        id: 1,
        name: "YieldYak",
      },
    };
  },
  mounted: function () {
    this.planetData = this.planet;
  },
};
</script>

<style scoped>
.PoolInfo {
  width: 290px;
  outline: 3px solid transparent;
  border-radius: 13px;
  -webkit-box-shadow: 0 8px 30px rgb(225 230 236 / 60%);
  box-shadow: 0 8px 30px rgb(225 230 236 / 60%);
  background-color: #fff;
}
.content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 23px;
  position: relative;
  border-radius: 13px;
  background-color: #fff;
}
.img-wrap {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 96px;
  height: 96px;
  border-radius: 50%;
  -webkit-box-shadow: inset 0 2px 10px #e1e6ec;
  box-shadow: inset 0 2px 10px #e1e6ec;
}
.pool-name {
  margin: 15px 0 0 0;
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 26px;
  text-align: center;
  letter-spacing: -0.4px;
  color: #414a5b;
}
.pool-id {
  margin-top: 12px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #838b9d;
}
.pool-apr {
  position: relative;
  text-align: center;
  min-width: 148px;
  margin-top: 26px;
  padding: 8px 14px;
  border: 1px solid rgba(217, 223, 234, 0.5);
  border-radius: 16px;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #838b9d;
}
.pool-buttons {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
}
.pool-button {
  margin: 10px;
}
.DarkMode .PoolInfo {
  box-shadow: none;
}
</style>